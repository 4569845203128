import React, {useEffect, useState} from 'react';

import Login from "./login"
// import Register from "./register"
import PassportInfo from './info'
import Forget from "./forget"
import {connect} from "react-redux";
import common from "../../utils/common";
import Oauth from "./oauth";

function Passport(props) {
    const {onClose} = props

    // 从外部应用跳转过来的时，会有 sequence 参数
    // 登录与注册页面之间需要传递scanKey，所以三个功能页面之间切换时，不使用路由跳转
    const searchParams = new URLSearchParams(props?.location?.search ? props.location.search.substring(1) : '')
    let sequenceStr = searchParams.get("sequence")
    let name = searchParams.get("name")
    let returnUrl = searchParams.get("returnUrl")

    // 邀请码
    let inviteCode = searchParams.get("invite")

    // 显示哪个页面：login  forget oauth info
    let [show, setShow] = useState('')

    // 微信扫码提到的key，用于注册时绑定微信
    let [scanKey, setScanKey] = useState('')

    useEffect(() => {

        // 记录推荐码
        if (inviteCode) {
            window.localStorage.setItem("invite", inviteCode)
        }

        // 有场景值，说明是从别的项目跳转过来，使用代码酷帐号登录第三方应用
        if (sequenceStr != null && sequenceStr.length > 0) {
            setShow('oauth')
            return
        }

        // 用于在右上角点注册或登录按扭时，直接显示指定的界面
        // 登录 /login?type=1
        // 注册 /login?type=2
        // 重置密码 /login?type=3
        // const searchParams = new URLSearchParams(props.location.search.substring(1))
        let type = searchParams.get("type")

        if (type === '3') {
            // 重置密码
            setShow('forget')
        } else {
            // 启用二维码的情况：先扫码，再判断是否需要注册
            // 未启用二维码的情况：先验证手机短信，再判断是否需要注册
            // 所以对于 type为1和2都是显示login组件
            setShow('login')
        }

    }, [])

    const redirect = () => {
        if (sequenceStr != null && sequenceStr.length > 0) {

            let apiStr = common.getApiUrl("/api/oauth/back", {
                token: common.getToken(),
                sequence: sequenceStr
            })

            window.location = apiStr
            return
        }

        if (returnUrl) {
            window.location.href = returnUrl;
            return;
        }

        if (onClose) {
            onClose()
        } else {
            window.location.replace(common.getReturnUrl(common.getFullUrl('/')))
        }
    }

    return (
        <>


            {(show === 'info') ?
                <PassportInfo {...{setShow, redirect}}/> : ''}

            {(show === 'login') ?
                <Login {...{setShow, scanKey, setScanKey, redirect}}/> : ''}

            {/*{(show === 'register') ?*/}
            {/*    <Register*/}
            {/*        {...{setShow, scanKey, redirect}}/> : ''}*/}

            {(show === 'forget') ?
                <Forget setShow={setShow}/> : ''}

            {(show === 'oauth') ?
                <Oauth redirect={redirect} name={name} setShow={setShow}/> : ''}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearUser: () => {
            dispatch({type: 'CLEAR_USER'})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Passport);

